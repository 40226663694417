<template>
  <div class="timetables">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <DataTable
      class="p-datatable-sm"
      :rowClass="rowClass"
      :rowHover="true"
      :value="timetables"
      :loading="isLoading"
      editMode="cell"
      :lazy="true"
      filterDisplay="row"
      :filters.sync="tableState.filters"
      :totalRecords="getTimetableCount"
      stateStorage="local"
      :stateKey="tableState.filterName"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :sortField="tableState.sortField"
      :expandedRows.sync="expandedRows"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h1 class="page-title">{{ $t('timetables') }}</h1>
          </div>
          <div class="table-header-right">
            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateTimetable">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column field="number" header="#" :styles="{ 'min-width': '40px' }"> </Column>

      <Column
        field="startAt"
        :header="$t('start')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          <strong> {{ $dayjs(slotProps.data[slotProps.column.field]).format('dddd') }}</strong
          ><br />
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>
        <!-- 
        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template> -->
      </Column>
      <Column
        field="endAt"
        :header="$t('end')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          <strong> {{ $dayjs(slotProps.data[slotProps.column.field]).format('dddd') }}</strong
          ><br />
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>
      </Column>

      <Column field="description" :header="$t('description')" :styles="{ 'min-width': '200px' }">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </Column>
      <Column field="contents" :header="$t('contents')" v-if="isAdmin">
        <template #body="slotProps">
          <multiselect
            class="multiselect-contents"
            v-model="slotProps.data.contents"
            :options="filteredContents"
            track-by="number"
            search-change="onFilterContents"
            v-on:select="onSelectContent(slotProps)"
            v-on:remove="onSelectContent(slotProps)"
            :filter="true"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :placeholder="'Content ' + $t('select') + '/suchen'"
          >
            <template #singleLabel="{ option }">
              {{ option.name ? option.name : 'Unnamed Content' }}
            </template>
            <template #option="{ option }">
              {{ option.name ? option.name : 'Unnamed Content' }}
            </template>
            <template #tag="{ option }">
              <div>{{ option.name ? option.name : 'Unnamed Content' }}</div>
            </template>
            <template #noResult>
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>
      </Column>

      <Column field="users" :header="$t('user')" v-if="isAdmin">
        <template #body="slotProps">
          <multiselect
            class="multiselect-users"
            v-model="slotProps.data.users"
            :options="filteredUsers"
            track-by="number"
            v-on:search-change="onFilterUsers"
            v-on:select="onSelectUser(slotProps)"
            v-on:remove="onSelectUser(slotProps)"
            :filter="true"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :placeholder="'Benutzer ' + $t('select') + '/suchen'"
          >
            <template #singleLabel="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template #option="{ option }"> {{ option.firstname }} {{ option.lastname }} </template>
            <template #tag="{ option }">
              <div>{{ option.firstname }} {{ option.lastname }}</div>
            </template>
            <template #noResult>
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>

        <template #filter>
          <InputText :value="tableState.customFilters.userSearch.value" @input="onUserSearch" />
        </template>
      </Column>

      <Column :styles="{ 'min-width': '10px' }">
        <template #body="slotProps">
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data.number"
            :confirmCallback="onDeleteTimetable"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-times mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('deleteEntry') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('deleteEntry') }}
            </template>
          </ConfirmButton>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConfirmButton from '@/components/ConfirmButton';

import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Multiselect from 'vue-multiselect';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'Timetables',
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    Calendar,
    InputText,
    ConfirmButton,
    Multiselect,
  },
  data() {
    return {
      expandedRows: [],
      filteredContents: [],
      filteredUsers: [],
      contents: [],
      users: [],
      tableState: {
        filterName: 'timetables-table-filters',
        customFilterName: 'timetables-custom-table-filters',
        filters: {
          description: { value: null, matchMode: FilterMatchMode.CONTAINS },
          startAt: {
            value: this.$dayjs()
              .startOf('week')
              .toDate(),
            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
          },
          endAt: {
            value: this.$dayjs()
              .startOf('week')
              .add(6, 'days')
              .endOf('day')
              .toDate(),
            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
          },
          users: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
        customFilters: {
          contentSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },

        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'startAt',
        sortOrder: -1,
      },
      defaultCustomFilters: {
        userSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['isLoading', 'getTimetables', 'getContents', 'getUsers', 'getTimetableCount']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('timetables') }];
    },
    timetables() {
      return this.getTimetables.filter(
        (timetable, index, self) => index === self.findIndex((t) => t.number === timetable.number)
      );
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
  },
  methods: {
    ...mapActions([
      'fetchTimetables',
      'createTimetable',
      'updateTimetable',
      'fetchContents',
      'deleteTimetable',
      'initTimetable',
      'fetchUsers',
    ]),

    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      const weekday = this.$dayjs(data.startAt)
        .locale('en')
        .format('dddd')
        .toLowerCase();

      return data.number === '_new' ? 'new' : weekday;
    },

    async onDeleteTimetable(timetableNumber) {
      try {
        await this.deleteTimetable(timetableNumber);
        this.fetchTimetables();
      } catch (error) {
        console.error('Failed to delete timetable:', error);
      }
    },

    onFilterContents(query) {
      this.filteredContents = this.getContents.filter(
        (content) => content.name && content.name.toLowerCase().includes(query.toLowerCase())
      );
    },
    async onFilterUsers(query) {
      // console.log(query);
      this.filteredUsers = [];
      this.getUsers.map((user) => {
        if (
          (user.firstname && user.firstname.toLowerCase().indexOf(query) >= 0) ||
          (user.lastname && user.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredUsers.push(user);
        }
      });
    },
    async onSelectContent(row) {
      const { data } = row;
      await this.save(data);
    },
    async onSelectUser(row) {
      const { data } = row;
      await this.save(data);
    },
    /**
     * Search for assigned users and save state to local storage
     */
    async onUserSearch(value) {
      if (this.tableState.customFilters.userSearch) {
        this.tableState.customFilters.userSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      if (field === 'startAt') {
        data.endAt = this.$dayjs(newValue)
          .add(3, 'hour')
          .toDate();
      }
      if (field === 'endAt') {
        data.endAt = newValue;
      }
      data[field] = newValue;
      await this.save(data);
    },
    async save(timetable) {
      if (timetable.number === '_new') {
        await this.createTimetable(timetable);
      } else {
        await this.updateTimetable(timetable);
      }
    },
    async onCreateTimetable() {
      await this.initTimetable();
      await this.fetchTimetables();
    },
    async onFilter() {
      await this.fetchTimetables();
    },

    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      await this.fetchTimetables();
    },

    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      await this.fetchTimetables();
    },

    async onStateRestore() {
      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage || this.defaultCustomFilters;
      await this.fetchTimetables();
    },

    async onContentSearch(value) {
      this.tableState.customFilters.contentSearch.value = value;
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      await this.fetchTimetables();
    },
  },

  async created() {
    await this.fetchContents();
    this.filteredContents = this.getContents;
    await this.fetchTimetables();
  },
  async mounted() {
    await this.fetchUsers({
      page: 0,
      pageSize: 1000,
      sortField: 'lastname',
      sortOrder: 1,
      filters: { activeState: { value: 'ACTIVE' } },
    });
    this.filteredUsers = this.getUsers;
  },
};
</script>

<style scoped lang="scss">
.timetables ::v-deep .center .p-column-header-content {
  justify-content: center;
}

.timetables ::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
.timetables ::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: lightgreen;
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: green;
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.monday {
  background: rgba(233, 207, 179, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.monday:hover {
  background: rgba(233, 207, 179, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.tuesday {
  background: rgba(217, 188, 130, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.tuesday:hover {
  background: rgba(217, 188, 130, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.wednesday {
  background: rgba(201, 176, 166, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.wednesday:hover {
  background: rgba(201, 176, 166, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.thursday {
  background: rgba(217, 227, 224, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.thursday:hover {
  background: rgba(217, 227, 224, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.friday {
  background: rgba(210, 208, 157, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.friday:hover {
  background: rgba(210, 208, 157, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.saturday {
  background: rgba(205, 215, 193, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.saturday:hover {
  background: rgba(205, 215, 193, 1);
}

.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.sunday {
  background: rgba(228, 233, 222, 0.7);
}
.timetables ::v-deep .p-datatable .p-datatable-tbody > tr.sunday:hover {
  background: rgba(228, 233, 222, 1);
}

.timetables ::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

/** Fix Dropdown overlay */
.timetables .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

.timetables ::v-deep .p-datepicker-title {
  display: flex;
}

.timetables ::v-deep .btn-icon > button {
  padding: 0;
}

.timetables .btn-xs {
  border-radius: 0;
}

.timetables ::v-deep .p-column-filter-clear-button {
  display: none;
}
.timetables ::v-deep .p-column-filter-menu-button {
  display: none;
}
.timetables .expansion {
  text-align: left;
  margin-left: 1.5em;
}
.timetables .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1em;
}
.multiselect-users {
  min-width: 180px;
  display: inline-block;
}
.timetables .grid > div {
  padding: 1em 1em;
  border: 2px;
  border: 1px solid $gray-300;
  background: white;
}
.timetables .flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  gap: 0.3em;
  margin-bottom: 0.2em;
}

.timetables .flex-row > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
}

.timetables ::v-deep .p-dialog {
  min-width: 500px;
}

/** Fix Dropdown overlay */
.timetables .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
